import { HeaderProps } from '../../utilities/craft-data/prepare-header-props'
import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

const LocaleSwitcher = ({
    localeLinks,
    minimal = false,
}: {
    localeLinks: HeaderProps['localeLinks']
    minimal?: boolean
}): JSX.Element => (
    <Container>
        <ButtonGroup>
            {localeLinks.map(({ id, isCurrent, language, languagePrettyName, slug }) =>
                isCurrent ? (
                    <p key={id}>
                        {minimal ? language.toUpperCase() : `${languagePrettyName} (${language.toUpperCase()})`}
                    </p>
                ) : (
                    <Link to={slug} key={id} rel="alternate">
                        {minimal ? language.toUpperCase() : `${languagePrettyName} (${language.toUpperCase()})`}
                    </Link>
                )
            )}
        </ButtonGroup>
    </Container>
)

export default LocaleSwitcher

const Container = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
`

const ButtonGroup = styled.div`
    overflow: hidden;
    border: 0.125rem solid var(--color-green);
    border-radius: 2rem;
    display: flex;

    > * {
        display: block;
        font-size: var(--font-size-extra-small);
        padding: 0.375rem 1.125rem 0.375rem 1.25rem;
        border-right: 0.125rem solid var(--color-green);
        margin: 0;
    }

    > :last-child {
        padding: 0.375rem 1.25rem 0.375rem 1.125rem;
        border: 0;
    }

    > p {
        background-color: var(--color-green);
        color: var(--color-white);
    }

    > a {
        color: inherit;

        &:hover {
            opacity: 0.8;
        }

        &:focus {
            outline: none;
            background-color: var(--color-yellow);
            opacity: 0.8;
        }
    }
`

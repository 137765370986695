import { materialUiTheme } from '../core/global-styles'
import React from 'react'
import styled from 'styled-components'
import { ThemeProvider } from '@material-ui/core'

const PageWrapper = ({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element => (
    <ThemeProvider theme={materialUiTheme}>
        <PageContainer>{children}</PageContainer>
    </ThemeProvider>
)

export default PageWrapper

const PageContainer = styled.div`
    @media screen {
        min-height: 100vh;
    }
    display: flex;
    flex-direction: column;

    > main {
        margin: 0 0 auto;
    }
`

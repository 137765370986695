const misterGreenBase = {
    allLocales: [
        {
            site: 'misterGreenNetherlands',
            siteId: 1,
            siteType: 'main',
            tenant: 'mistergreen_nl',
            country: 'nl',
            countryPrettyName: 'Nederland',
            language: 'nl',
            languagePrettyName: 'Nederlands',
            baseUrl: 'https://www.mistergreen.nl',
            imageUrl: 'https://static.mistergreen.nl',
            localeIndependentCountryName: 'Nederland',
            buildTimeFormIds: ['cf4b8df6-5f00-4dc6-8aa0-f0ea92672a64'],
            hasCheckout: true,
        },
        {
            site: 'misterGreenBelgiumDutch',
            siteId: 4,
            siteType: 'main',
            tenant: 'mistergreen_be',
            country: 'be',
            countryPrettyName: 'België',
            language: 'nl',
            languagePrettyName: 'Nederlands',
            baseUrl: 'https://www.mistergreenlease.be/nl',
            imageUrl: 'https://static.mistergreenlease.be',
            localeIndependentCountryName: 'Belgium',
            buildTimeFormIds: [],
            hasCheckout: true,
        },
        {
            site: 'mistergreenBelgiumFrench',
            siteId: 6,
            siteType: 'main',
            tenant: 'mistergreen_be',
            country: 'be',
            countryPrettyName: 'Belgique',
            language: 'fr',
            languagePrettyName: 'Français',
            baseUrl: 'https://www.mistergreenlease.be/fr',
            imageUrl: 'https://static.mistergreenlease.be',
            localeIndependentCountryName: 'Belgium',
            buildTimeFormIds: [],
            hasCheckout: true,
        },
        {
            site: 'misterGreenSpain',
            siteId: 12,
            siteType: 'main',
            tenant: 'mistergreen_es',
            country: 'es',
            countryPrettyName: 'España',
            language: 'es',
            languagePrettyName: 'Español',
            baseUrl: 'https://www.mistergreen.es',
            imageUrl: 'https://static.mistergreen.es',
            localeIndependentCountryName: 'España',
            buildTimeFormIds: [],
            hasCheckout: false,
        },
        {
            site: 'misterGreenFrance',
            siteId: 14,
            siteType: 'main',
            tenant: 'mistergreen_fr',
            country: 'fr',
            countryPrettyName: 'France',
            language: 'fr',
            languagePrettyName: 'Français',
            baseUrl: 'https://www.mistergreenlease.fr',
            imageUrl: 'https://static.mistergreenlease.fr',
            localeIndependentCountryName: 'France',
            buildTimeFormIds: [],
            hasCheckout: true,
        },
        {
            site: 'misterGreenPortugal',
            siteId: 11,
            siteType: 'main',
            tenant: 'mistergreen_pt',
            country: 'pt',
            countryPrettyName: 'Portugal',
            language: 'pt',
            languagePrettyName: 'Português',
            baseUrl: 'https://www.mistergreen.pt',
            imageUrl: 'https://static.mistergreen.pt',
            localeIndependentCountryName: 'Portugal',
            buildTimeFormIds: [],
            hasCheckout: false,
        },
        {
            site: 'misterGreenUK',
            siteId: 7,
            siteType: 'main',
            tenant: 'mistergreen_gb',
            country: 'gb',
            countryPrettyName: 'United Kingdom',
            language: 'en',
            languagePrettyName: 'English',
            baseUrl: 'https://www.mistergreenlease.co.uk',
            imageUrl: 'https://static.mistergreenlease.co.uk',
            localeIndependentCountryName: 'United Kingdom',
            buildTimeFormIds: [],
            hasCheckout: false,
        },
        {
            site: 'misterGreenGermany',
            siteId: 8,
            siteType: 'main',
            tenant: 'mistergreen_de',
            country: 'de',
            countryPrettyName: 'Deutschland',
            language: 'de',
            languagePrettyName: 'English',
            baseUrl: 'https://www.mistergreen.de',
            imageUrl: 'https://static.mistergreen.de',
            localeIndependentCountryName: 'Deutschland',
            buildTimeFormIds: [],
            hasCheckout: true,
        },
        {
            site: 'misterGreenDenmark',
            siteId: 13,
            siteType: 'main',
            tenant: 'mistergreen_dk',
            country: 'dk',
            countryPrettyName: 'Danmark',
            language: 'da',
            languagePrettyName: 'Dansk',
            baseUrl: 'https://www.mistergreen.dk',
            imageUrl: 'https://static.mistergreen.dk',
            localeIndependentCountryName: 'Danmark',
            buildTimeFormIds: [],
            hasCheckout: true,
        },
        {
            site: 'ready2ShareNetherlands',
            siteId: 9,
            siteType: 'r2s',
            tenant: 'mistergreen_nl',
            country: 'nl',
            countryPrettyName: 'Nederland',
            language: 'nl',
            languagePrettyName: 'Nederlands',
            baseUrl: 'https://www.ready2share.net',
            imageUrl: 'https://static.mistergreen.nl',
            localeIndependentCountryName: 'Nederland',
            buildTimeFormIds: [],
            hasCheckout: false,
        },
        {
            site: 'misterGreenFund',
            siteId: 10,
            siteType: 'fund',
            tenant: 'mistergreen_nl',
            country: '',
            countryPrettyName: '',
            language: 'en',
            languagePrettyName: 'English',
            baseUrl: 'https://www.mistergreen.fund',
            imageUrl: 'https://static.mistergreen.nl',
            localeIndependentCountryName: '',
            buildTimeFormIds: [],
            hasCheckout: false,
        },
    ],
}

export default {
    ...misterGreenBase,
    locales: misterGreenBase.allLocales.filter(locale => {
        const siteType = process.env.SITE_TYPE || 'main'

        if (siteType !== 'main') {
            return locale.siteType === siteType
        }

        return locale.country === process.env.COUNTRY_CODE && locale.siteType === siteType
    }),
}

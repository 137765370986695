import { BaseLocaleObject, CraftEntry, LocaleObject, MisterGreenConfig } from './types'
import misterGreenConfig from '../../../mistergreen-config'
import React from 'react'

const { locales }: MisterGreenConfig = misterGreenConfig

export interface LocaleLink {
    language: string
    languagePrettyName: string
    slug: string
    id: number
    isCurrent: string
}

export function getCurrentImageUrl(): string {
    return locales[0].imageUrl
}

export function withLocalePrefix(): boolean {
    return locales.length > 1
}

export function getLocalePrefix(language: string): string {
    return withLocalePrefix() && language ? `/${language}/` : '/'
}

export function getLocaleSlug(entry: CraftEntry, language: LocaleObject['language']): string {
    const localePrefix = getLocalePrefix(language)
    let slug = entry.slug

    if (entry.typeHandle === 'misterGreenApp') {
        slug = slug.replace(/---/g, '/')
    }

    return entry.typeHandle !== 'homepage' ? `${localePrefix}${slug}` : localePrefix
}

export function getLocaleString(locale: BaseLocaleObject | LocaleObject): string {
    if (!locale.country) {
        return locale.language
    }

    return `${locale.language}-${locale.country.toUpperCase()}`
}

export const Locale = React.createContext<LocaleObject>({
    site: '',
    siteId: 1,
    siteType: '',
    tenant: '',
    country: '',
    language: '',
    languagePrettyName: '',
    baseUrl: '',
    imageUrl: '',
    localeIndependentCountryName: '',
    buildTimeFormIds: [],
    currencyCode: '',
    hasCheckout: false,
})

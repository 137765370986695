import AccountIcon from '../ui/account-icon'
import FlexibleLink from './link'
import Hamburger from '../ui/hamburger'
import { HeaderProps, MenuItem } from '../../utilities/craft-data/prepare-header-props'
import Icon from '../ui/icon'
import Image, { ImageVersion } from '../craft-cms/image'
import { Link } from 'gatsby'
import { Locale, getLocalePrefix, withLocalePrefix } from '../../utilities/general/locale'
import LocaleSwitcher from './locale-switcher'
import Logo from '../ui/logo'
import React, { useContext, useState } from 'react'
import ShoppingCart from './shopping-cart'
import styled, { css } from 'styled-components'
import SocialMediaLinks from './social-media-links'
import useWindowEventListener from '../../utilities/hooks/use-window-event-listener'

const Header = ({
    configuratorUrls,
    isFullNavBar,
    isNavBarSeeThrough,
    localeLinks,
    logoVersion = 'default',
    menuItems = [],
    renderAccountButton = false,
    renderShoppingCart = false,
    shoppingCartLabels,
    socialUrls,
}: HeaderProps): JSX.Element => {
    const navBarItems = menuItems.filter((item: MenuItem) => item.displayType === 'inNavBar')
    const additionalItemsWithIcon = menuItems.filter(
        (item: MenuItem) => item.displayType === 'inAdditionalMenuWithIcon'
    )
    const additionalItemsWithImage = menuItems.filter(
        (item: MenuItem) => item.displayType === 'inAdditionalMenuWithImage'
    )

    const { language } = useContext(Locale)
    const [isAdditionalMenuOpen, toggleAdditionalMenuVisibility] = useState(false)

    const eventListener = (event: Event) => {
        if (isAdditionalMenuOpen && !(event.target as HTMLElement).closest('[data-additional-menu]')) {
            toggleAdditionalMenuVisibility(false)
        }
    }

    useWindowEventListener(eventListener)

    return (
        <>
            <HeaderContainer scrollsWithPage={isFullNavBar} isNavBarSeeThrough={isNavBarSeeThrough}>
                <InnerContainer>
                    <LogoContainer isNavBarSeeThrough={isNavBarSeeThrough}>
                        <Link
                            to={getLocalePrefix(language)}
                            style={{
                                color: `white`,
                                textDecoration: `none`,
                            }}
                            title="MisterGreen homepage"
                        >
                            <Logo version={logoVersion} />
                        </Link>
                    </LogoContainer>
                    {menuItems.length > 0 && (
                        <NavContainer>
                            {isFullNavBar && (
                                <List>
                                    {navBarItems.map(
                                        (item, index) =>
                                            !!item && (
                                                <Item key={index}>
                                                    <FlexibleLink data={item.linkArray[0]} />
                                                </Item>
                                            )
                                    )}
                                </List>
                            )}
                            <AdditionalNavItemsContainer>
                                {renderShoppingCart && shoppingCartLabels && (
                                    <ShoppingCartContainer isNavBarSeeThrough={isNavBarSeeThrough}>
                                        <ShoppingCart
                                            configuratorUrls={configuratorUrls}
                                            shoppingCartLabels={shoppingCartLabels}
                                            useWhiteVersion={isNavBarSeeThrough}
                                        />
                                    </ShoppingCartContainer>
                                )}
                                {renderAccountButton && (
                                    <AccountButtonContainer isNavBarSeeThrough={isNavBarSeeThrough}>
                                        <AccountButton href="account" title="My MisterGreen">
                                            <AccountIcon />
                                        </AccountButton>
                                    </AccountButtonContainer>
                                )}
                                {menuItems.length > 0 && (
                                    <AdditionalMenuButton
                                        onClick={() => toggleAdditionalMenuVisibility(!isAdditionalMenuOpen)}
                                        onKeyPress={event =>
                                            event.keyCode === 13 &&
                                            toggleAdditionalMenuVisibility(!isAdditionalMenuOpen)
                                        }
                                        hideOnDesktop={
                                            [...additionalItemsWithIcon, ...additionalItemsWithImage].length === 0 &&
                                            isFullNavBar
                                        }
                                        isNavBarSeeThrough={isNavBarSeeThrough}
                                        title="Menu"
                                    >
                                        <Hamburger open={isAdditionalMenuOpen} />
                                    </AdditionalMenuButton>
                                )}
                            </AdditionalNavItemsContainer>
                        </NavContainer>
                    )}
                </InnerContainer>
            </HeaderContainer>
            {!isNavBarSeeThrough && <HeaderSpacer />}
            {menuItems.length > 0 && (
                <AdditionalMenuWrapper
                    open={isAdditionalMenuOpen}
                    scrollsWithPage={isFullNavBar}
                    hideOnDesktop={
                        [...additionalItemsWithIcon, ...additionalItemsWithImage].length === 0 && isFullNavBar
                    }
                >
                    <AdditionalMenu>
                        <div data-additional-menu>
                            {[...navBarItems, ...additionalItemsWithIcon].length > 0 && (
                                <AdditionalMenuListWithIcons>
                                    {[...navBarItems, ...additionalItemsWithIcon].map(
                                        (item, index) =>
                                            !!item && (
                                                <IconListItem key={index}>
                                                    <Icon type={item.icon} />
                                                    <FlexibleLink data={item.linkArray[0]} />
                                                </IconListItem>
                                            )
                                    )}
                                </AdditionalMenuListWithIcons>
                            )}
                            {additionalItemsWithImage.length > 0 && (
                                <AdditionalMenuListWithImages>
                                    {additionalItemsWithImage.map((item, index) =>
                                        !!item && !!item.image.length && !!item.image[0].filename ? (
                                            <ImageListItem key={index}>
                                                <Image data={item} version={ImageVersion.Small} />
                                                <FlexibleLink data={item.linkArray[0]} />
                                            </ImageListItem>
                                        ) : (
                                            <ImageListItem key={index} withoutImage={true}>
                                                <FlexibleLink data={item.linkArray[0]} />
                                            </ImageListItem>
                                        )
                                    )}
                                </AdditionalMenuListWithImages>
                            )}
                            <SocialMediaContainer>
                                {!!socialUrls && <SocialMediaLinks socialUrls={socialUrls} />}
                            </SocialMediaContainer>
                            {withLocalePrefix() && (
                                <LocaleContainer>
                                    <LocaleSwitcher localeLinks={localeLinks} />
                                </LocaleContainer>
                            )}
                        </div>
                    </AdditionalMenu>
                </AdditionalMenuWrapper>
            )}
        </>
    )
}

export default Header

const minMinisculeBreakpoint = '23.375rem'
const minTinyBreakpoint = '26rem'
const minSmallBreakpoint = '36.5rem'
const minMediumBreakpoint = '55rem'
const minLargeBreakpoint = '80.5rem'

interface StyledHeaderProps {
    scrollsWithPage: boolean
    isNavBarSeeThrough: boolean
}

const HeaderSpacer = styled.div`
    height: var(--header-height);
`

const HeaderContainer = styled.header<StyledHeaderProps>`
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: rgba(239, 239, 239, 0.97);
    ${props =>
        !props.scrollsWithPage &&
        css`
            position: absolute;
        `}
    ${props =>
        props.isNavBarSeeThrough &&
        css`
            background-color: transparent;
        `}
`

const InnerContainer = styled.div`
    max-width: var(--page-width);
    padding: 0 var(--gutter-width);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const LogoContainer = styled.div<{ isNavBarSeeThrough: boolean }>`
    height: var(--header-height);
    width: 6rem;
    padding-top: 0.375rem;
    display: flex;
    align-items: center;
    img,
    svg {
        height: 100%;
        width: 100%;
    }
    ${props =>
        props.isNavBarSeeThrough &&
        css`
            svg {
                color: var(--color-white);
            }
        `}
    @media (min-width: ${minMinisculeBreakpoint}) {
        width: 8.125rem;
    }
    @media (min-width: ${minTinyBreakpoint}) {
        width: 10.25rem;
    }
`

const NavContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

const List = styled.ul`
    display: none;
    @media (min-width: ${minLargeBreakpoint}) {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: flex-end;
    }
`

const sharedItemStyles = css`
    margin: 0 0 1rem;
    a {
        color: inherit;
        font-family: var(--font-headings);
        font-size: 1rem;
        font-weight: var(--font-weight-bold);
        &:hover {
            opacity: 0.7;
        }
        &[aria-current] {
            color: var(--color-green-dark);
        }
    }
`

const Item = styled.li`
    ${sharedItemStyles}
    @media (min-width: ${minMediumBreakpoint}) {
        margin: 0 0 0 3rem;
    }
`

const AdditionalNavItemsContainer = styled.div`
    padding: 0 0 0 0.125rem;
    display: flex;
    @media (min-width: ${minMinisculeBreakpoint}) {
        padding: 0 0 0 1rem;
    }
`

const ShoppingCartContainer = styled.div<{ isNavBarSeeThrough: boolean }>`
    height: 2.5rem;
    border-right: 0.0625rem solid var(--color-green);
    ${props =>
        props.isNavBarSeeThrough &&
        css`
            border-color: var(--color-white);
        `}
`

const AccountButtonContainer = styled.div<{ isNavBarSeeThrough: boolean }>`
    height: 2.5rem;
    border-right: 0.0625rem solid var(--color-green);
    ${props =>
        props.isNavBarSeeThrough &&
        css`
            border-color: var(--color-white);
            svg {
                color: var(--color-white);
            }
        `}
`

const AccountButton = styled.a`
    display: block;
    height: 100%;
    padding: 0.4375rem 0.125rem 0.3125rem;
    margin: 0 0.75rem;
`

interface AdditionalMenuButtonProps {
    hideOnDesktop: boolean
    isNavBarSeeThrough: boolean
}

const AdditionalMenuButton = styled.button<AdditionalMenuButtonProps>`
    background-color: transparent;
    padding: 0.5rem 0 0.5rem 0.5rem;
    border: 0;
    margin: 0 0 0 0.75rem;
    cursor: pointer;
    &:hover,
    &:focus {
        outline: none;
        opacity: 1;
    }
    ${props =>
        props.hideOnDesktop &&
        css`
            @media (min-width: ${minMediumBreakpoint}) {
                display: none;
            }
        `}
    ${props =>
        props.isNavBarSeeThrough &&
        css`
            span {
                background-color: var(--color-white);
            }
        `}
`

interface AdditionalMenuWrapperProps {
    open: boolean
    scrollsWithPage: boolean
    hideOnDesktop: boolean
}

const AdditionalMenuWrapper = styled.div<AdditionalMenuWrapperProps>`
    width: 100%;
    position: absolute;
    top: var(--header-height);
    left: 0;
    z-index: 2;
    opacity: 1;
    transition: all 300ms ease;
    transform-origin: top center;
    @media (min-width: ${minMediumBreakpoint}) {
        top: calc(var(--header-height) + 1rem);
    }
    ${props =>
        !props.open &&
        css`
            visibility: hidden;
            pointer-events: none;
            opacity: 0;
            transform: translateY(-1rem);
        `}
    ${props =>
        props.scrollsWithPage &&
        css`
            position: fixed;
            > div > div {
                @media (min-width: ${minMediumBreakpoint}) {
                    max-height: calc(100vh - var(--header-height) - 1rem);
                    overflow-y: scroll;
                    scrollbar-width: none;
                    &::-webkit-scrollbar {
                        display: none;
                    }
                }
            }
        `}
  ${props =>
        props.hideOnDesktop &&
        css`
            @media (min-width: ${minMediumBreakpoint}) {
                display: none;
            }
        `}
`

const AdditionalMenu = styled.div`
    max-height: calc(100vh - var(--header-height));
    overflow-y: scroll;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
    > div {
        width: 100%;
        background-color: rgba(239, 239, 239, 0.97);
        padding: 1rem 1rem 3rem;
        display: grid;
        grid-gap: 1rem;
    }
    @media (min-width: ${minSmallBreakpoint}) {
        > div {
            grid-template-columns: 1fr 1fr;
            padding: 1rem 1rem 1.5rem;
        }
    }
    @media (min-width: ${minMediumBreakpoint}) {
        max-height: initial;
        max-width: var(--page-width);
        padding: 0 var(--gutter-width);
        margin: 0 auto;
        display: flex;
        justify-content: flex-end;
        > div {
            max-width: 45rem;
        }
    }
`

const AdditionalMenuListWithIcons = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`

const IconListItem = styled.li`
    height: 3.75rem;
    width: 100%;
    position: relative;
    padding: 0 0.5rem;
    margin: 0 0 0.5rem;
    display: flex;
    align-items: center;
    &:hover {
        color: var(--color-green);
        background: #fafafa;
    }
    a {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        color: inherit;
        font-family: var(--font-headings);
        font-size: 1rem;
        font-weight: var(--font-weight-bold);
        padding: 0 0 0 3.75rem;
        display: flex;
        align-items: center;
        &[aria-current] {
            color: var(--color-green-dark);
        }
    }
    svg {
        height: auto;
        width: 2.5rem;
    }
`

const AdditionalMenuListWithImages = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`

const ImageListItem = styled.li<{ withoutImage?: boolean }>`
    position: relative;
    height: 8rem;
    width: 100%;
    overflow: hidden;
    margin: 0 0 0.5rem;
    &:hover {
        img {
            transform: scale(1.1);
        }
    }
    img {
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        transition: transform 300ms ease;
    }
    a {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        color: var(--color-white);
        font-family: var(--font-headings);
        font-size: 1rem;
        font-weight: var(--font-weight-bold);
        padding: 0.25rem 0.5rem;
        display: flex;
        align-items: flex-end;
    }
    ${props =>
        props.withoutImage &&
        css`
            height: 3.75rem;
            a {
                color: inherit;
                align-items: center;
                &:hover {
                    color: var(--color-green);
                }
            }
        `}
`

const SocialMediaContainer = styled.div`
    grid-column: 1;
    margin: 0 0.5rem;
    @media (min-width: ${minSmallBreakpoint}) {
        margin: 2rem 0.875rem 0;
    }
`

const LocaleContainer = styled.div`
    height: max-content;
    display: flex;
    @media (min-width: ${minSmallBreakpoint}) {
        justify-content: flex-end;
        margin: 2rem 0 0;
    }
`

import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { Locale } from '../../utilities/general/locale'
import { SeoProps } from '../../utilities/craft-data/prepare-seo-props'

const SEO = ({
    description,
    lang = '',
    title,
    author,
    pageLinks = [],
    image,
    noindex = false,
    keywords = [],
    cookieConsent = '',
    environment = 'production',
    hasFormOnPage = false,
}: SeoProps): JSX.Element => {
    if (hasFormOnPage) {
        pageLinks = [
            ...pageLinks,
            { rel: 'preconnect', href: 'https://forms.hsforms.com' },
            { rel: 'preconnect', href: 'https://js.hsforms.net' },
        ]
    }

    const { language, country } = useContext(Locale)
    const consentLocale = language && country ? `${language}-${country.toUpperCase()}` : 'en'
    const useInlinedCookieConsent = !!cookieConsent && !cookieConsent.trim().startsWith('<')

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            link={pageLinks}
            script={
                useInlinedCookieConsent
                    ? [
                          {
                              type: 'text/javascript',
                              innerHTML: cookieConsent,
                          },
                      ]
                    : [
                          {
                              type: 'text/javascript',
                              src: `//consent.mistergreen.club/${consentLocale}.js`,
                          },
                      ]
            }
            meta={[
                {
                    name: `description`,
                    content: description,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: description,
                },
                {
                    property: `og:image`,
                    content: image,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: author,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: description,
                },
                {
                    property: `twitter:image`,
                    content: image,
                },
                noindex || (environment && !environment.startsWith('production'))
                    ? {
                          property: `robots`,
                          content: `noindex`,
                      }
                    : {},
                !!keywords && !!keywords.length
                    ? {
                          property: `keywords`,
                          content: keywords.join(', '),
                      }
                    : {},
            ]}
        />
    )
}

export default SEO

import { ConfiguratorUrls } from '../../utilities/craft-data/prepare-header-props'
import { getLocaleString, Locale } from '../../utilities/general/locale'
import React, { Fragment, useContext, useState } from 'react'
import ShoppingCartIcon from '../ui/shopping-cart-icon'
import styled, { css } from 'styled-components'
import useDealFeed from '../../utilities/hooks/use-deal-feed'
import useUserToken from '../../utilities/hooks/use-user-token'
import useWindowEventListener from '../../utilities/hooks/use-window-event-listener'

export interface ShoppingCartLabels {
    shoppingCartMainHeading: string
    dealLinkText: string
    configurationStatusText: string
    offerStatusText: string
    contractStatusText: string
    priceSuffixExclVat: string
    priceSuffixInclVat: string
    shoppingCartLoggedOutHeading: string
    loggedOutText: string
    logInLinkText: string
    shoppingCartNoDealsYetHeading: string
    noDealsYetText: string
    noDealLinkTemplate: string
}

interface ShoppingCartProps {
    configuratorUrls: ConfiguratorUrls | undefined
    shoppingCartLabels: ShoppingCartLabels
    useWhiteVersion: boolean
}

const ShoppingCart = ({ configuratorUrls, shoppingCartLabels, useWhiteVersion }: ShoppingCartProps): JSX.Element => {
    const locale = useContext(Locale)
    const localeString = getLocaleString(locale)
    const userToken = useUserToken()
    const deals = useDealFeed({ configuratorUrls, language: locale.language, shoppingCartLabels, userToken })
    const [isDropdownVisible, setIsDropdownVisible] = useState(false)

    const eventListener = (event: Event) => {
        const target = event?.target as HTMLElement

        if (target?.closest('[data-dropdown-button]')) {
            setIsDropdownVisible(!isDropdownVisible)
        } else if (isDropdownVisible && !target?.closest('[data-dropdown-click-target]')) {
            setIsDropdownVisible(false)
        }
    }

    useWindowEventListener(eventListener)

    const dealsList = (
        <DealsList>
            {deals.slice(0, 4).map((deal, index) => (
                <Deal key={index}>
                    <NameBox>
                        <ModelName>{deal.modelName}</ModelName>
                        <EditionName>{deal.editionName}</EditionName>
                    </NameBox>
                    <PriceBox>
                        <Price>€{Intl.NumberFormat(localeString).format(Math.round(deal.price))}</Price>
                        <PriceSuffix>
                            {deal.incVAT
                                ? shoppingCartLabels.priceSuffixInclVat
                                : shoppingCartLabels.priceSuffixExclVat}
                        </PriceSuffix>
                    </PriceBox>
                    <StatusBox>{deal.status}</StatusBox>
                    {!!deal.url && <LinkBox href={deal.url}>{shoppingCartLabels.dealLinkText}</LinkBox>}
                </Deal>
            ))}
        </DealsList>
    )

    const bottomLinkWrapper = !!configuratorUrls && (
        <BottomLinkWrapper>
            {Object.entries(configuratorUrls).map(([key, url]: [string, unknown]) => {
                const prettyModelName = key[0].toUpperCase() + key.slice(1, 5) + ' ' + key[5]

                return url && shoppingCartLabels?.noDealLinkTemplate !== null ? (
                    <BottomLink href={url as string} key={key}>
                        {shoppingCartLabels.noDealLinkTemplate.replace(/\{\{\s*modelName\s*\}\}/g, prettyModelName)}
                    </BottomLink>
                ) : (
                    <Fragment key={key}></Fragment>
                )
            })}
        </BottomLinkWrapper>
    )

    return (
        <Container data-dropdown-click-target>
            <ShoppingCartButton
                useWhiteVersion={useWhiteVersion}
                amountOfDeals={deals.length}
                title="Shopping cart"
                data-dropdown-button
            >
                {deals.length > 0 && <ShoppingCartNumber>{deals.length}</ShoppingCartNumber>}
                <ShoppingCartIcon />
            </ShoppingCartButton>
            <DropdownContainer isVisible={isDropdownVisible}>
                {!!userToken && deals.length > 0 ? (
                    <>
                        <MainHeading>{shoppingCartLabels.shoppingCartMainHeading}</MainHeading>
                        {dealsList}
                    </>
                ) : !userToken ? (
                    <>
                        <MainHeading>{shoppingCartLabels.shoppingCartLoggedOutHeading}</MainHeading>
                        <Paragraph>{shoppingCartLabels.loggedOutText}</Paragraph>
                        <BottomLink href="account">{shoppingCartLabels.logInLinkText}</BottomLink>
                        {deals.length > 0 ? dealsList : bottomLinkWrapper}
                    </>
                ) : (
                    <>
                        <MainHeading>{shoppingCartLabels.shoppingCartNoDealsYetHeading}</MainHeading>
                        <Paragraph>{shoppingCartLabels.noDealsYetText}</Paragraph>
                        {bottomLinkWrapper}
                    </>
                )}
            </DropdownContainer>
        </Container>
    )
}

export default ShoppingCart

const minSmallBreakpoint = '23.375rem'
const minMediumBreakpoint = '47rem'

const Container = styled.div`
    position: relative;
`

const ShoppingCartButton = styled.button<{ useWhiteVersion: boolean; amountOfDeals: number }>`
    display: block;
    width: 3rem;
    position: relative;
    z-index: 1;
    background-color: transparent;
    padding: 0.0625rem 0.375rem;
    border: 0;
    margin: 0 0.75rem 0 0;
    cursor: pointer;

    ${props =>
        props.useWhiteVersion &&
        css`
            svg {
                color: var(--color-white);
            }
        `}

    @media (min-width: ${minSmallBreakpoint}) {
        margin: 0 0.75rem;
    }
`

const ShoppingCartNumber = styled.span`
    height: 1.25rem;
    width: 1.25rem;
    position: absolute;
    top: -0.25rem;
    right: -0.125rem;
    background-color: var(--color-red);
    color: var(--color-white);
    font-family: var(--font-headings);
    font-size: var(--font-size-extra-small);
    line-height: 1;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const DropdownContainer = styled.div<{ isVisible: boolean }>`
    width: calc(100vw - 1.5rem);
    position: absolute;
    top: 4rem;
    right: -7.5rem;
    z-index: 1;
    transform: scaleY(0);
    opacity: 0;
    background-color: var(--color-white);
    padding: 1.125rem 1.25rem 1.375rem;
    border: 0.0625rem solid var(--color-grey-dark);
    border-radius: 0.5rem;
    box-shadow: var(--box-shadow-more);
    transition: opacity 0.15s ease-in;

    ${props =>
        props.isVisible &&
        css`
            transform: scaleY(1);
            opacity: 1;
        `}

    &::before {
        content: '';
        height: 1rem;
        width: 1rem;
        position: absolute;
        top: -0.5625rem;
        right: 9.25rem;
        z-index: 0;
        transform: rotate(45deg);
        background-color: var(--color-white);
        border-top: 0.0625rem solid var(--color-grey-dark);
        border-left: 0.0625rem solid var(--color-grey-dark);
    }

    @media (min-width: ${minSmallBreakpoint}) {
        width: calc(100vw - 3rem);
        right: -6.75rem;

        &::before {
            right: 8.5rem;
        }
    }

    @media (min-width: ${minMediumBreakpoint}) {
        width: 28rem;
        right: -0.5rem;

        &::before {
            right: 2.25rem;
        }
    }
`

const MainHeading = styled.h2`
    color: var(--color-black-light);
    font-size: var(--font-size-large);
    margin: 0 0 1.25rem;
`

const Paragraph = styled.p``

const BottomLink = styled.a`
    display: inline-block;
    background-color: var(--color-green);
    color: var(--color-white);
    font-size: var(--font-size-small);
    text-align: center;
    padding: 0.25rem 0.75rem;
    border-radius: 0.125rem;
`

const BottomLinkWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;

    @media (min-width: ${minSmallBreakpoint}) {
        grid-template-columns: 1fr 1fr;
    }

    ${BottomLink} + & {
        padding-top: 1.125rem;
        border-top: 0.0625rem solid var(--color-grey-dark);
        margin-top: 1.125rem;
    }
`

const DealsList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;

    ${BottomLink} + & {
        padding-top: 1.125rem;
        border-top: 0.0625rem solid var(--color-grey-dark);
        margin-top: 1.125rem;
    }
`

const Deal = styled.li`
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 0.25rem 0.75rem;

    &:not(:first-of-type) {
        padding-top: 1rem;
        border-top: 0.0625rem solid var(--color-grey-dark);
        margin-top: 1rem;
    }
`

const NameBox = styled.h3`
    margin: 0;
`

const ModelName = styled.span`
    display: block;
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-medium);
`

const EditionName = styled.span`
    display: block;
    color: var(--color-grey-dark);
    font-family: var(--font-body);
    font-size: var(--font-size-extra-small);
    font-weight: var(--font-weight-regular);
`

const PriceBox = styled.p`
    line-height: var(--line-height-h1);
    text-align: right;
    margin: 0;
`

const Price = styled.span`
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-bold);
`

const PriceSuffix = styled.span`
    display: block;
    color: var(--color-grey-dark);
    font-family: var(--font-body);
    font-size: var(--font-size-extra-small);
    font-weight: var(--font-weight-regular);
`

const StatusBox = styled.p`
    font-size: var(--font-size-small);
    margin: 0;
`

const LinkBox = styled.a`
    font-size: var(--font-size-small);
    text-align: right;
`

import { generateMisterGreenFetchOptions } from './generateMisterGreenFetchOptions'

export default async (query: string): Promise<any> => {
    const token = window.localStorage.getItem('token')
    const options = generateMisterGreenFetchOptions(query, undefined, token)
    const result = await fetch(process.env.MISTERGREEN_API_URL as string, options).then(res => res.json())

    if (result?.errors?.some((error: Error) => error.message.startsWith('Context creation failed'))) {
        window.localStorage.removeItem('token')
    }

    return result.errors ? null : result.data
}

import { createGlobalStyle } from 'styled-components'
import { createMuiTheme } from '@material-ui/core'

export const materialUiTheme = createMuiTheme({
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Helvetica',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
        ].join(','),
        fontSize: 12,
    },
    palette: {
        text: {
            primary: '#222',
            secondary: '#333',
        },
        primary: {
            dark: '#40a58c',
            main: '#51BBA0',
        },
    },
})

export const typographyMaxWidthBreakpoint = '50rem'

const GlobalStyles = createGlobalStyle`
  :root {
    /*
      HEADER
    */

    --header-height: 4.55rem;

    /*
      HORIZONTAL SPACING
    */

    --page-width: 80.25rem;
    --max-single-element-width: 45rem;
    --gutter-width: 1.5rem;

    /*
      VERTICAL SPACING
    */

    --vertical-spacing: 4rem;
    --spacing-top-of-page: calc(1.5rem + 2vw);
    --spacing-elements-more: 2.5rem;
    --spacing-elements: 1.5rem;
    --spacing-elements-less: .75rem;

    /*
      GRADIENTS
    */

    --gradient-black: linear-gradient(29deg, #444 17%, #000 87%);
    --gradient-green: linear-gradient(29deg, #51bba0 17%, #00d49e 87%);
    --gradient-purple: linear-gradient(29deg, #CC2B5E 17%, #753A88 87%);
    --gradient-red: linear-gradient(29deg, #ef473a 17%, #cb2d3e 87%);
    --gradient-grey: linear-gradient(29deg, #f6f6f6 17%, #b6b6b6 87%);

    /*
      BASE COLORS
    */

    --color-black: #222;
    --color-black-light: #333;
    --color-white: #fff;
    --color-grey: rgba(144, 144, 144, .8);
    --color-grey-light: rgba(239, 239, 239, .8);
    --color-grey-extra-light: rgba(255, 255, 255, .9);
    --color-grey-dark: #5c5c5c;
    --color-grey-extra-dark: rgba(34, 34, 34, .75);
    --color-green: #51BBA0;
    --color-green-dark: #40a58c;
    --color-red: #ef473a;
    --color-yellow: rgba(241, 168, 14, .5);

    /*
      FONTS
    */

    --font-body: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
    --font-headings: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';

    /*
      FONT SIZES
    */

    --font-size-extra-extra-large: 2.25rem;
    --font-size-extra-large: 2rem;
    --font-size-large: 1.5rem;
    --font-size-medium: 1.125rem;
    --font-size: 1rem;
    --font-size-small: .875rem;
    --font-size-extra-small: .75rem;

    @media (max-width: ${typographyMaxWidthBreakpoint}) {
      --font-size-extra-extra-large: 2rem;
      --font-size-extra-large: 1.75rem;
      --font-size-large: 1.25rem;
      --font-size-medium: 1rem;
    }

    /*
      FONT WEIGHTS
    */

    --font-weight-bold: 600;
    --font-weight-medium: 500;
    --font-weight-regular: 400;

    /*
      LINE HEIGHT
    */

    --line-height-default: 1.6;
    --line-height-blockquotes: 1.45;
    --line-height-headings: 1.375;
    --line-height-h1: 1.2;

    /*
      BOX SHADOW
    */

    --box-shadow-less: 0 .4rem 2rem rgba(0, 0, 0, 0.2);
    --box-shadow: 0 .7rem 4rem rgba(0, 0, 0, 0.2);
    --box-shadow-more: 0 1rem 6rem rgba(0, 0, 0, 0.2);
  }

  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-display: block;
    src: local('Montserrat SemiBold'),
      local('Montserrat-SemiBold'),
      url('/montserrat-v13-latin-600.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-display: block;
    src: local('Montserrat Medium'),
      local('Montserrat-Medium'),
      url('/montserrat-v13-latin-500.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: local('Montserrat Regular'),
      local('Montserrat-Regular'),
      url('/montserrat-v13-latin-regular.woff2') format('woff2');
  }

  /*
    Turn off smooth scrolling for people who've
    expressed a preference for reduced motion.
  */
  @media screen and (prefers-reduced-motion: reduce) {
    html {
      scroll-behavior: auto;
    }
  }

  *,
  ::before,
  ::after {
    box-sizing: border-box;
  }

  @media print {
      html, body {
          height: 99%;
      }
  }

  body {
    background-color: var(--color-white);
    color: var(--color-black);
    font-family: var(--font-body);
    font-size: var(--font-size);
    letter-spacing: 0.021rem;
    line-height: var(--line-height-default);
    margin: 0;

    @media print {
      margin: 2.5mm 2.5mm 2.5mm 2.5mm;
      @page {
        size: auto;
        margin: 2%;
      }

      #hubspot-messages-iframe-container {
        display: none !important;
      }
    }
  }

  h1 {
    font-family: var(--font-headings);
    font-size: var(--font-size-extra-extra-large);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-h1);
  }

  h2 {
    font-family: var(--font-headings);
    font-size: var(--font-size-extra-large);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-headings);
  }

  h3 {
    font-family: var(--font-headings);
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-medium);
    line-height: var(--line-height-headings);
  }

  h4, h5, h6 {
    font-family: var(--font-headings);
    font-size: var(--font-size);
    line-height: var(--line-height-headings);
  }

  a {
    color: var(--color-green-dark);
    text-decoration: none;
    transition: opacity .15s ease-in-out;

    &:hover {
      opacity: .9;
    }
  }

  button {
    letter-spacing: inherit;
  }

  blockquote {
    font-size: var(--font-size-large);
    line-height: var(--line-height-blockquotes);
    padding: 0 0 0 1rem;
    border-left: 0.375rem solid var(--color-green);
  }

  blockquote.twitter-tweet {
    width: 100%;
    max-width: 31rem;
    min-width: 14rem;
    position: relative;
    background-color: #fff;
    color: #1c2022;
    font-size: .875rem;
    padding: 1.25rem 1.25rem .725rem;
    border: .0625rem solid #e1e8ed;
    border-radius: .25rem;
    margin: 3.5rem auto;

    &:last-child {
      margin: 3.5rem auto 0;
    }

    &::after {
      content: "";
      display: inline-block;
      height: 1.25rem;
      width: 1.25rem;
      position: absolute;
      top: .5rem;
      right: .5rem;
      background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2072%2072%22%3E%3Cpath%20fill%3D%22none%22%20d%3D%22M0%200h72v72H0z%22%2F%3E%3Cpath%20class%3D%22icon%22%20fill%3D%22%231da1f2%22%20d%3D%22M68.812%2015.14c-2.348%201.04-4.87%201.744-7.52%202.06%202.704-1.62%204.78-4.186%205.757-7.243-2.53%201.5-5.33%202.592-8.314%203.176C56.35%2010.59%2052.948%209%2049.182%209c-7.23%200-13.092%205.86-13.092%2013.093%200%201.026.118%202.02.338%202.98C25.543%2024.527%2015.9%2019.318%209.44%2011.396c-1.125%201.936-1.77%204.184-1.77%206.58%200%204.543%202.312%208.552%205.824%2010.9-2.146-.07-4.165-.658-5.93-1.64-.002.056-.002.11-.002.163%200%206.345%204.513%2011.638%2010.504%2012.84-1.1.298-2.256.457-3.45.457-.845%200-1.666-.078-2.464-.23%201.667%205.2%206.5%208.985%2012.23%209.09-4.482%203.51-10.13%205.605-16.26%205.605-1.055%200-2.096-.06-3.122-.184%205.794%203.717%2012.676%205.882%2020.067%205.882%2024.083%200%2037.25-19.95%2037.25-37.25%200-.565-.013-1.133-.038-1.693%202.558-1.847%204.778-4.15%206.532-6.774z%22%2F%3E%3C%2Fsvg%3E');
      background-repeat: no-repeat;
      background-size: contain;
    }

    p {
      font-family: Helvetica, Roboto, 'Segoe UI', Calibri, sans-serif;
      font-size: 1rem;
      line-height: 1.4;
      white-space: pre-wrap;
      padding: 0 0 1.25rem;
      border-bottom: .0625rem solid #e1e8ed;
      margin: 0 0 .75rem;
    }

    a,
    a:visited {
      color: #2b7bb9;
    }

    > a {
      display: block;
    }
  }

  table:not([class]) {
    width: 100%;
    border-collapse: collapse;

    caption {
      font-style: italic;
      text-align: left;
      padding: .375rem .5rem;
    }

    th, td {
      text-align: left;
      padding: .375rem .5rem;
      border: .0625rem solid var(--color-grey-light);
    }
  }
`

export default GlobalStyles

import Icon from '../ui/icon'
import React from 'react'
import { SocialUrls } from '../../utilities/craft-data/get-social-urls'
import styled, { css } from 'styled-components'

const SocialMediaLinks = ({
    socialUrls,
    turnGreenOnHover = true,
}: {
    socialUrls: SocialUrls
    turnGreenOnHover?: boolean
}): JSX.Element => (
    <Container>
        {!!socialUrls?.newsletter && (
            <Item socialMediaChannel="newsletter" turnGreenOnHover={turnGreenOnHover}>
                <a href={socialUrls.newsletter} target="_blank" rel="noopener noreferrer" title="Newsletter">
                    <Icon type="mailbox" />
                </a>
            </Item>
        )}
        {!!socialUrls?.instagram && (
            <Item socialMediaChannel="instagram" turnGreenOnHover={turnGreenOnHover}>
                <a href={socialUrls.instagram} target="_blank" rel="noopener noreferrer" title="Instagram">
                    <Icon type="instagram" />
                </a>
            </Item>
        )}
        {!!socialUrls?.facebook && (
            <Item socialMediaChannel="facebook" turnGreenOnHover={turnGreenOnHover}>
                <a href={socialUrls.facebook} target="_blank" rel="noopener noreferrer" title="Facebook">
                    <Icon type="facebook" />
                </a>
            </Item>
        )}
        {!!socialUrls?.linkedin && (
            <Item socialMediaChannel="linkedin" turnGreenOnHover={turnGreenOnHover}>
                <a href={socialUrls.linkedin} target="_blank" rel="noopener noreferrer" title="LinkedIn">
                    <Icon type="linkedin" />
                </a>
            </Item>
        )}
        {!!socialUrls?.twitter && (
            <Item socialMediaChannel="twitter" turnGreenOnHover={turnGreenOnHover}>
                <a href={socialUrls.twitter} target="_blank" rel="noopener noreferrer" title="Twitter">
                    <Icon type="twitter" />
                </a>
            </Item>
        )}
    </Container>
)

export default SocialMediaLinks

const Container = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
`

const Item = styled.li<{ socialMediaChannel: string; turnGreenOnHover: boolean }>`
    margin: 0 0.875rem 0 0;
    cursor: pointer;
    transition: all 0.15s ease;

    ${props =>
        props.socialMediaChannel === 'newsletter' &&
        css`
            svg {
                height: 1.625rem;
            }
        `}

    ${props =>
        props.socialMediaChannel === 'instagram' &&
        css`
            svg {
                height: 2rem;
            }
        `}

  ${props =>
        props.socialMediaChannel === 'facebook' &&
        css`
            svg {
                height: 1.5rem;
            }
        `}

  ${props =>
        props.socialMediaChannel === 'linkedin' &&
        css`
            svg {
                height: 1.875rem;
            }
        `}

  ${props =>
        props.socialMediaChannel === 'twitter' &&
        css`
            svg {
                height: 1.3125rem;
            }
        `}

  a {
        height: 100%;
        color: inherit;
        display: flex;
        align-items: center;
    }

    &:hover {
        transform: translateY(-0.125rem);

        ${props =>
            props.turnGreenOnHover &&
            css`
                color: var(--color-green);
            `}
    }
`

import { ConfiguratorUrls } from '../craft-data/prepare-header-props'
import { getLocalePrefix } from './locale'
import { ShoppingCartLabels } from '../../components/core/shopping-cart'

type ApiDealStatus = 'CONFIGURATION' | 'OFFER' | 'CONTRACT_INCOMPLETE' | 'CONTRACT_COMPLETE'

export interface ApiDeal {
    id: string
    modelName: string
    editionName: string
    status: ApiDealStatus
    pricing: {
        pricePerMonthExVAT: number
        pricePerMonthIncVAT: number
        incVAT: boolean
    }
}

export interface UiDeal {
    modelName: string
    editionName: string
    incVAT: boolean
    price: number
    status: string
    url: string | null
}

const isDealFirstOfType = (apiDeals: ApiDeal[], currentDealIndex: number): boolean => {
    const currentDeal = apiDeals[currentDealIndex]
    const firstOfTypeIndex = apiDeals.findIndex(
        ({ status, modelName }: ApiDeal) => status === currentDeal.status && modelName === currentDeal.modelName
    )

    return currentDealIndex === firstOfTypeIndex
}

const getConfiguratorUrl = (configuratorUrls: ConfiguratorUrls | undefined, modelName: string): string | null => {
    if (!configuratorUrls) {
        return null
    }

    const urlIndex = modelName[0].toLowerCase() + modelName.slice(1).replace(/\s/g, '')
    return configuratorUrls[urlIndex as keyof ConfiguratorUrls] || null
}

interface GetDealFeedParam {
    apiDeals: ApiDeal[]
    configuratorUrls: ConfiguratorUrls | undefined
    language: string
    shoppingCartLabels: ShoppingCartLabels
}

export const getDealFeed = ({
    apiDeals,
    configuratorUrls,
    language,
    shoppingCartLabels,
}: GetDealFeedParam): UiDeal[] => {
    const includesProgressedDeals = apiDeals.some((deal: ApiDeal) => deal.status !== 'CONFIGURATION')

    return apiDeals.reduce((acc: UiDeal[], deal: ApiDeal, idx: number) => {
        if (deal.status === 'CONFIGURATION' && includesProgressedDeals) {
            return acc
        }

        if (deal.status === 'CONFIGURATION' && !isDealFirstOfType(apiDeals, idx)) {
            return acc
        }

        return [
            ...acc,
            {
                modelName: `Tesla ${deal.modelName}`,
                editionName: deal.editionName,
                incVAT: deal.pricing.incVAT,
                price: deal.pricing.incVAT ? deal.pricing.pricePerMonthIncVAT : deal.pricing.pricePerMonthExVAT,
                status: getDealStatusText(shoppingCartLabels, deal.status),
                url:
                    deal.status === 'CONFIGURATION'
                        ? getConfiguratorUrl(configuratorUrls, deal.modelName)
                        : `${getLocalePrefix(language)}account/offer/${deal.id}`,
            },
        ]
    }, [])
}

const getDealStatusText = (shoppingCartLabels: ShoppingCartLabels, status: ApiDealStatus): string => {
    if (status === 'CONFIGURATION') {
        return shoppingCartLabels.configurationStatusText
    }

    if (status === 'OFFER') {
        return shoppingCartLabels.offerStatusText
    }

    return shoppingCartLabels.contractStatusText
}

const innerDealFragment = `
  id
  modelName
  editionName
  status
  pricing {
    pricePerMonthExVAT
    pricePerMonthIncVAT
    incVAT
  }
`

export const dealCollectionQuery = `
  query {
    DealCollection(
      filter: {
        OR: [
          { status: CONFIGURATION },
          { status: OFFER },
          { status: CONTRACT_INCOMPLETE },
          { status: CONTRACT_COMPLETE },
        ]
      }
      orderBy: {
        field: "updatedAt",
        asc: false
      }
      pagination: {
        limit: 8
      }
    ) {
      deals {
        ${innerDealFragment}
      }
    }
  }
`

export const getDealQuery = (dealId: string): string => `
  query {
    Deal(id: "${dealId}") {
      ${innerDealFragment}
    }
  }
`

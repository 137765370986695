import { useEffect } from 'react'

const useWindowEventListener = (eventListener: (event: any) => void, eventName = 'click') => {
    useEffect(() => {
        if (!window?.addEventListener) {
            return
        }

        window.addEventListener(eventName, eventListener)

        return () => {
            window.removeEventListener(eventName, eventListener)
        }
    })
}

export default useWindowEventListener

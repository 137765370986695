import { Link } from 'gatsby'
import React, { useContext } from 'react'

import { getLocaleSlug, Locale } from '../../utilities/general/locale'
import { CraftEntry } from '../../utilities/general/types'

export interface LinkData {
    text: string
    linkUrl?: string
    entry?: CraftEntry[]
    isPrimaryButton?: boolean
}

const FlexibleLink = ({ data }: { data: LinkData }): JSX.Element => {
    const { language } = useContext(Locale)
    const linkUrl = data?.linkUrl || ''
    const target = linkUrl.startsWith('http') && !linkUrl.includes('#') ? '_blank' : '_self'
    const rel = linkUrl.startsWith('http') && !linkUrl.includes('#') ? 'noopener noreferrer' : undefined

    if (data === undefined || (data.entry && !data.entry.length)) {
        return <></>
    }

    return !linkUrl && data?.entry ? (
        <Link to={getLocaleSlug(data.entry[0], language)}>{data?.text || ''}</Link>
    ) : (
        <a href={linkUrl} target={target} rel={rel}>
            {data?.text || ''}
        </a>
    )
}

export default FlexibleLink

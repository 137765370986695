import React from 'react'
import styled, { css } from 'styled-components'

export interface HamburgerProps {
    open: boolean
}

const Hamburger = ({ open }: HamburgerProps): JSX.Element => (
    <Container open={open}>
        <span />
        <span />
        <span />
    </Container>
)

export default Hamburger

const Container = styled.div<{ open: boolean }>`
    height: 1rem;
    width: 1.875rem;
    position: relative;

    span {
        display: block;
        width: 1.875rem;
        height: 0.125rem;
        background-color: var(--color-green);
        transition: all 250ms ease;

        &:not(:first-child) {
            margin: 0.375rem 0 0;
        }
    }

    ${props =>
        props.open &&
        css`
            span:first-child {
                transform: translateY(0.6875rem) rotate(45deg);
            }

            span:nth-child(2) {
                opacity: 0;
                transform: translateX(0.625rem);
            }

            span:last-child {
                transform: translateY(-0.3125rem) rotate(-45deg);
            }
        `}
`

import { useEffect, useState } from 'react'

const useUserToken = (): string => {
    const [token, setToken] = useState(typeof window !== 'undefined' ? window.localStorage.getItem('token') : '')

    const updateTokenOnChangeInDifferentTab = (event: StorageEvent) => {
        if (event.key === 'token') {
            localStorage.setItem('token', event.newValue || '')
            setToken(event.newValue || '')
        }
    }

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('storage', updateTokenOnChangeInDifferentTab)

            return function cleanUp() {
                window.removeEventListener('storage', updateTokenOnChangeInDifferentTab)
            }
        }
    })

    return token || ''
}

export default useUserToken

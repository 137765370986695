interface FetchOptions {
    method: string
    headers: { [key: string]: string }
    body: string
}

export function generateMisterGreenFetchOptions(query: string, tenant?: string, token?: string | null): FetchOptions {
    const headers: FetchOptions['headers'] = {
        'apollographql-client-name': 'marketing-site',
        'apollographql-client-version': '1.0',
        'Content-Type': 'application/json',
    }

    if (tenant) {
        headers.tenant = tenant
    }

    if (token) {
        headers['Authorization'] = `Bearer ${token}`
    }

    return {
        method: 'POST',
        headers,
        body: JSON.stringify({ query }),
    }
}

import { ContactDetailsItem } from '../../utilities/craft-data/prepare-footer-props'
import React, { Fragment } from 'react'
import styled from 'styled-components'

const ContactDetails = ({ contactDetails }: { contactDetails: ContactDetailsItem[] }): JSX.Element => (
    <Container>
        {contactDetails.map((item, idx) => (
            <Fragment key={idx}>
                <dt>{item.textLabel}</dt>
                <dd>
                    <a href={item.type === 'email' ? `mailto:${item.value}` : `tel:${item.value}`}>{item.value}</a>
                </dd>
            </Fragment>
        ))}
    </Container>
)

export default ContactDetails

const Container = styled.dl`
    margin: 0;

    dt {
        filter: grayscale(1);
        font-size: var(--font-size-small);
        white-space: nowrap;

        &:not(:first-of-type) {
            margin: 1.5rem 0 0;
        }
    }

    dd {
        margin: 0;

        a {
            font-size: var(--font-size-small);

            &:hover {
                text-decoration: underline;
            }
        }
    }
`

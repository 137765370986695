import { ApiDeal, dealCollectionQuery, getDealFeed, getDealQuery, UiDeal } from '../general/deals'
import { ConfiguratorUrls } from '../craft-data/prepare-header-props'
import query from '../general/query'
import { ShoppingCartLabels } from '../../components/core/shopping-cart'
import { useEffect, useState } from 'react'

interface UseDealFeedParam {
    configuratorUrls: ConfiguratorUrls | undefined
    language: string
    shoppingCartLabels: ShoppingCartLabels
    userToken: string
}

const useDealFeed = ({ configuratorUrls, language, shoppingCartLabels, userToken }: UseDealFeedParam): UiDeal[] => {
    const cachedDeals = typeof window !== 'undefined' ? sessionStorage.getItem('deals') : JSON.stringify([])
    const [deals, setDeals] = useState<UiDeal[]>(cachedDeals ? JSON.parse(cachedDeals) : [])

    useEffect(() => {
        const updateDeals = (apiDeals: ApiDeal[]) => {
            let deals: UiDeal[] = []

            if (apiDeals?.length) {
                deals = getDealFeed({ apiDeals, configuratorUrls, language, shoppingCartLabels })
            }

            sessionStorage.removeItem('deals')
            setDeals(deals)
            sessionStorage.setItem('deals', JSON.stringify(deals))
        }

        const checkouts =
            typeof window !== 'undefined' ? localStorage.getItem('unconnectedCheckouts') : JSON.stringify({})
        const modelIds = checkouts ? JSON.parse(checkouts)?.modelId : null

        // If user is logged out and there are unconnected checkouts, get the unconnected checkouts.
        if (!userToken && modelIds) {
            const dealIds: string[] = Object.values(modelIds)

            Promise.all<{ Deal: ApiDeal }>(
                dealIds.map((dealId: string) => {
                    return query(getDealQuery(dealId))
                })
            ).then((result: { Deal: ApiDeal }[]) => {
                const apiDeals: ApiDeal[] = result.map((res: { Deal: ApiDeal }) => res?.Deal).filter(v => !!v)
                updateDeals(apiDeals)
            })
        }

        // If user is logged in, get the deals owned by the user.
        if (userToken) {
            query(dealCollectionQuery).then(result => {
                const apiDeals = result?.DealCollection?.deals
                updateDeals(apiDeals)
            })
        }
    }, [configuratorUrls, language, shoppingCartLabels, userToken])

    return deals
}

export default useDealFeed

import ContactDetails from './contact-details'
import { FooterProps, ContactDetailsItem } from '../../utilities/craft-data/prepare-footer-props'
import { Link as GatsbyLink } from 'gatsby'
import Link from './link'
import LocaleSwitcher from './locale-switcher'
import Logo from '../ui/logo'
import React, { Fragment, useContext } from 'react'
import SocialMediaLinks from './social-media-links'
import { SocialUrls } from '../../utilities/craft-data/get-social-urls'
import styled, { css } from 'styled-components'
import { withLocalePrefix, Locale, getLocalePrefix } from '../../utilities/general/locale'

type AdditionalInfoContainerVersion = 'small-screens-only' | 'big-screen-only'

const FooterAdditionalInfoContainer = ({
    contactDetails,
    socialUrls,
    version,
}: {
    contactDetails: ContactDetailsItem[]
    socialUrls: SocialUrls | undefined
    version: AdditionalInfoContainerVersion
}) => (
    <AdditionalInfoContainer as={version === 'small-screens-only' ? 'li' : 'div'} version={version}>
        {!!socialUrls && (
            <SocialMediaContainer>
                <SocialMediaLinks socialUrls={socialUrls} turnGreenOnHover={false} />
            </SocialMediaContainer>
        )}
        {!!contactDetails && contactDetails.length !== 0 && (
            <ContactDetailsContainer>
                <ContactDetails contactDetails={contactDetails} />
            </ContactDetailsContainer>
        )}
    </AdditionalInfoContainer>
)

const Footer = ({
    footerLinkColumns = [],
    footerReviewLink = [],
    footerBottomLinks = [],
    footerBottomText = '',
    socialUrls,
    contactDetails,
    localeLinks,
    withTopMargin = true,
    useReady2ShareFooter = false,
}: FooterProps): JSX.Element => {
    const { language } = useContext(Locale)
    const shouldRenderSocialIcons = socialUrls && Object.values(socialUrls).some(url => url.length)

    return (
        <FooterContainer withTopMargin={withTopMargin} useLightVersion={useReady2ShareFooter}>
            <InnerContainer>
                <LogoContainer>
                    <GatsbyLink
                        to={getLocalePrefix(language)}
                        style={{
                            color: `white`,
                            textDecoration: `none`,
                        }}
                        aria-label="MisterGreen homepage"
                    >
                        <Logo version={useReady2ShareFooter ? 'r2s' : 'grey'} />
                    </GatsbyLink>
                    {!!footerReviewLink.length && (
                        <ReviewLink href={footerReviewLink[0].src} target="_blank" rel="noopener noreferrer">
                            <img
                                src={footerReviewLink[0].imageUrl}
                                alt={footerReviewLink[0].alt}
                                loading="lazy"
                                height="91"
                                width="32"
                            />
                        </ReviewLink>
                    )}
                </LogoContainer>
                {(!!footerLinkColumns.length || contactDetails.length !== 0 || shouldRenderSocialIcons) && (
                    <MainContainer useNarrowVersion={footerLinkColumns.length === 1}>
                        {!!footerLinkColumns.length && (
                            <LinkColumns>
                                {footerLinkColumns.map((column, idx) => (
                                    <Fragment key={idx}>
                                        <LinkColumn>
                                            <ColumnHeading>{column.heading}</ColumnHeading>
                                            <LinksContainer>
                                                {column.footerLinks.map(({ linkArray }, index) => (
                                                    <ListItem key={index}>
                                                        <Link data={linkArray[0]} />
                                                    </ListItem>
                                                ))}
                                            </LinksContainer>
                                        </LinkColumn>
                                        {idx === 0 && (
                                            <FooterAdditionalInfoContainer
                                                contactDetails={contactDetails}
                                                socialUrls={shouldRenderSocialIcons ? socialUrls : undefined}
                                                version={'small-screens-only'}
                                            />
                                        )}
                                    </Fragment>
                                ))}
                            </LinkColumns>
                        )}
                        <FooterAdditionalInfoContainer
                            contactDetails={contactDetails}
                            socialUrls={shouldRenderSocialIcons ? socialUrls : undefined}
                            version={'big-screen-only'}
                        />
                    </MainContainer>
                )}
                {withLocalePrefix() && (
                    <LocaleContainer>
                        <LocaleSwitcher localeLinks={localeLinks} />
                    </LocaleContainer>
                )}
                {!!footerBottomLinks.length && (
                    <FooterBottomLinks>
                        {footerBottomLinks.map(({ linkArray }, index) => (
                            <Fragment key={index}>
                                <Link data={linkArray[0]} />
                                {index !== footerBottomLinks.length - 1 && (
                                    <FooterBottomLinkDivider>-</FooterBottomLinkDivider>
                                )}
                            </Fragment>
                        ))}
                    </FooterBottomLinks>
                )}
                {!!footerBottomText && <BottomText dangerouslySetInnerHTML={{ __html: footerBottomText }} />}
            </InnerContainer>
        </FooterContainer>
    )
}

const extraSmallMaxWidthBreakpoint = '32.4375rem'
const extraSmallMinWidthBreakpoint = '32.5rem'

const smallMaxWidthBreakpoint = '47.4375rem'
const smallMinWidthBreakpoint = '47.5rem'

interface FooterStyleProps {
    withTopMargin: boolean
    useLightVersion: boolean
}

const FooterContainer = styled.footer<FooterStyleProps>`
    background-image: var(--gradient-black);

    ${props =>
        props.withTopMargin &&
        css`
            margin: var(--vertical-spacing) 0 0;
        `}

    ${props =>
        props.useLightVersion &&
        css`
            background-image: var(--gradient-grey);

            div,
            dt,
            dd > a {
                color: var(--color-black);
            }
        `}
`

const InnerContainer = styled.div`
    max-width: var(--page-width);
    color: var(--color-white);
    padding: 3.25rem var(--gutter-width) 1.25rem;
    margin: auto auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    * {
        color: inherit;
    }
`

const LogoContainer = styled.div`
    > * {
        max-width: 12.5rem;
        margin: 1rem auto;
    }

    img {
        height: auto;
        width: 100%;
    }

    @media (min-width: 30rem) {
        display: flex;
        justify-content: center;
        align-items: center;

        > * {
            margin: 0 1.5rem;

            @media (min-width: ${smallMinWidthBreakpoint}) {
                margin: 0 3.5rem;
            }
        }
    }
`

const ReviewLink = styled.a`
    width: max-content;
    display: block;

    img {
        display: block;
        height: 2rem;
        width: auto;
        margin: 0 auto;
    }
`

const MainContainer = styled.div<{ useNarrowVersion: boolean }>`
    width: 100%;
    margin: 1rem 0 3rem;

    @media (min-width: ${extraSmallMinWidthBreakpoint}) {
        margin: 3rem 0;
    }

    @media (min-width: ${smallMinWidthBreakpoint}) {
        margin: 4.5rem 0;
        display: grid;
        grid-template-columns: 1fr 14rem;
        grid-gap: 1.25rem;
    }

    @media (min-width: 65rem) {
        grid-template-columns: 1fr 16rem;
    }

    ${props =>
        props.useNarrowVersion &&
        css`
            max-width: 40rem;
        `}
`

const LinkColumns = styled.ul`
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;

    @media (min-width: ${extraSmallMinWidthBreakpoint}) {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
        grid-gap: 2.5rem 1.25rem;
    }
`

const LinkColumn = styled.li`
    @media (max-width: ${extraSmallMaxWidthBreakpoint}) {
        order: 2;
        margin-top: 1.5rem;
    }
`

const ColumnHeading = styled.h2`
    font-size: var(--font-size-extra-large);
    font-weight: var(--font-weight-light);
    margin: 0;

    @media (min-width: ${extraSmallMinWidthBreakpoint}) {
        margin-bottom: 0.125rem;
    }
`

const LinksContainer = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`

const ListItem = styled.li`
    width: 100%;

    > a {
        display: inline-block;
        font-size: var(--font-size-small);
        padding: 0.25rem;

        &:hover {
            text-decoration: underline;
        }
    }
`

const AdditionalInfoContainer = styled.div<{ version: AdditionalInfoContainerVersion }>`
    padding: 0.75rem 0 0;

    @media (max-width: ${extraSmallMaxWidthBreakpoint}) {
        order: 1;
    }

    ${props =>
        props.version === 'small-screens-only' &&
        css`
            @media (min-width: ${smallMinWidthBreakpoint}) {
                display: none;
            }
        `}

    ${props =>
        props.version === 'big-screen-only' &&
        css`
            @media (max-width: ${smallMaxWidthBreakpoint}) {
                display: none;
            }
        `}
`

const SocialMediaContainer = styled.div`
    color: var(--color-white);
    margin: 0 0 1.5rem;

    @media (max-width: ${extraSmallMaxWidthBreakpoint}) {
        > * {
            justify-content: center;
        }
    }
`

const ContactDetailsContainer = styled.div``

const LocaleContainer = styled.div`
    margin: 0 0 2rem;

    ${LogoContainer} + & {
        margin-top: 2rem;
    }
`

const FooterBottomLinks = styled.p`
    font-size: var(--font-size-small);
    margin: 0;
    display: flex;
    align-items: center;

    a {
        padding: 0.25rem;

        &:hover {
            text-decoration: underline;
        }
    }

    @media (max-width: 42.5rem) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0.5rem 1rem;

        a:nth-of-type(odd) {
            text-align: right;
        }
    }

    @media (max-width: 22rem) {
        grid-template-columns: 1fr;

        a:nth-of-type(1n) {
            text-align: center;
        }
    }
`

const FooterBottomLinkDivider = styled.span`
    line-height: 100%;

    @media (max-width: 42.5rem) {
        display: none;
    }
`

const BottomText = styled.div`
    font-size: var(--font-size-small);
    text-align: center;
    margin: 0.75rem 0 0;

    p {
        margin: 0;

        a:hover {
            text-decoration: underline;
        }
    }
`

export default Footer
